import Moderator from '@/models/bread/Moderator/quest'

export default {
  key:'Quest',
  name:{
    singular:'Quest',
    plural:'Quests',
  },
  parents:[
    'Book'
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}