import i18n from "@/plugins/i18n";
export default {
  browse: {
    table: {
      headers: [
        {
          text: i18n.t("model.prop.no"),
          value: "index",
          sortable: false,
          filterable: false,
        },
        {
          text: "Quest Type Key",
          value: "questTypeKey",
        },
        {
          text: "Quest Type Reward",
          value: "questTypeReward",
        },
        {
          text: "Overwrite Reward",
          value: "overwriteReward",
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: "Info",
        label: i18n.t("string.info"),
      },
     
    ],
    table: {
      headers: [
        {
          text: "Quest Type Key",
          value: "questTypeKey",
        },
        {
          text: "Quest Type Reward",
          value: "questTypeReward",
        },
        {
          text: "Overwrite Reward",
          value: "overwriteReward",
        },
        {
          text: i18n.t("model.prop.created"),
          value: "timestampCreated",
          filterable: false,
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: "timestampUpdated",
          filterable: false,
        },
      ],
    },
  },
  add:{
    fields:[
      {
        type:'string',
        key:'key',
        name:'Key',
        defaultValue:null,
      },
      {
        type: 'model',
        key: 'questTypeKey',
        server:'game',
        name: 'Quest Type',
        model: 'questType',
        optionText: 'key',
        optionValue: 'key',
        defaultValue: 'CHAPTER_1',
      },
      {
        type: 'model',
        key: 'overwriteRewardItemKey',
        server:'game',
        name: 'Overwrite Reward Item',
        model: 'item',
        optionText: 'name',
        optionValue: 'key',
        defaultValue: null,
      },
      {
        type:'number',
        key:'overwriteRewardItemQuantity',
        name:'Overwrite Reward Item Quantity',
        min:0,
        defaultValue:0,
      },
    ],
    rules:{
      key: [v => !!v || 'Quest key is required'],
    },
  },
  edit:true,
  delete: true,
};
